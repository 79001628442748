import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    public_key: import.meta.env.VITE_FW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: 0,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: '',
      phone_number: '',
      name: '',
    },
    meta: {},
    customizations: {
      title: 'Roducate',
      description: 'Roducate Payment',
      logo: 'https://purple-roducate-files.s3.eu-west-1.amazonaws.com/pwa-192x192.png',
    },
  },
};

export const paymentConfig = createSlice({
  name: 'paymentConfig',
  initialState,
  reducers: {
    updatePaymentConfig: (state, item) => {
      state.value.meta = item.payload;
    },
    updateUserDetails: (state, item) => {
      const { customer, amount } = item.payload;
      state.value.customer = customer;
      state.value.amount = amount;
    },
    setPayData: (state, { payload }) => {
      // console.log(payload, 'setPayData')
      state.payData = payload;
    },
  },
});

export const { updatePaymentConfig, updateUserDetails, setPayData } =
  paymentConfig.actions;

export default paymentConfig.reducer;
