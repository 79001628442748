import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const teachatSlice = createApi({
  reducerPath: 'teachatSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get', 'MockExamTask'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getClasses: builder.query({
      query: () => `/learner/my-classes`,
    }),
    // getTasks: builder.query({
    //   query: () => `progress-trackers/tasks`,
    // }),

    // submitTask: builder.mutation({
    //   query: (payload) => ({
    //     url: 'progress-trackers/',
    //     method: 'POST',
    //     body: payload,
    //   }),
    // }),
    // mockExamProgress: builder.query({
    //   query: () => 'progress-trackers/mockexams',
    //   providesTags: ['MockExamTask'],
    // }),
    // submitMockExamTask: builder.mutation({
    //   query: (payload) => ({
    //     url: 'progress-trackers/mockexams',
    //     method: 'POST',
    //     body: payload,
    //   }),
    //   invalidatesTags: [{ type: 'MockExamTask' }],
    // }),
    // studyTask: builder.query({
    //   query: ({ id }) => `/resource/study/task/${id}`,
    // }),
    // studyTaskSubmit: builder.mutation({
    //   query: (payload) => ({
    //     url: 'progress-trackers/topic-questions',
    //     method: 'POST',
    //     body: payload,
    //   }),
    // }),
  }),
});

export const { useGetClassesQuery } = teachatSlice;
