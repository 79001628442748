import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {},
  chat: {},
  groupChat: {},
  shareObj: {
    type: '',
    id: '',
  },
  liveClass: {},
};

export const friendRequestSlice = createSlice({
  name: 'friendRequestSlice',
  initialState,
  reducers: {
    friendObj: (state, item) => {
      state.value = item.payload;
    },
    startChat: (state, item) => {
      state.chat = item.payload;
    },
    startGroupChat: (state, item) => {
      state.groupChat = item.payload;
    },
    startLiveClass: (state, item) => {
      state.liveClass = item.payload;
    },
    shareObjFuc: (state, item) => {
      state.shareObj = item.payload;
    },
  },
});

export const {
  friendObj,
  startChat,
  shareObjFuc,
  startGroupChat,
  startLiveClass,
} = friendRequestSlice.actions;

export default friendRequestSlice.reducer;
