import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const competitionSlice = createApi({
  reducerPath: 'competitionSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['COMPETITIONS', 'GET_COMPETITION', ['COMPETITION_SCORE']],
  endpoints: (builder) => ({
    getCompetitions: builder.query({
      query: () => `resource/competitions`,
      providesTags: ['COMPETITIONS'],
    }),
    getCompetitionScore: builder.query({
      query: (id) => `progress-trackers/Competitions/${id}`,
      providesTags: ['COMPETITION_SCORE'],
    }),
    joinCompetitions: builder.mutation({
      query: (id) => ({
        url: `resource/competitions/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: [{ type: 'COMPETITIONS' }],
    }),
    getCompetitionDetails: builder.query({
      query: (id) => ({
        url: `resource/competitions/${id}`,
      }),
      providesTags: [{ type: 'GET_COMPETITIONS' }],
    }),
    // ------- SUBMIT EACH COMPETITION TASK QUESTION
    submitCompetitionQuestions: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/competitions',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCompetitionDetailsQuery,
  useGetCompetitionsQuery,
  useJoinCompetitionsMutation,
  useSubmitCompetitionQuestionsMutation,
  useGetCompetitionScoreQuery,
} = competitionSlice;
