import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const groupsSlice = createApi({
  reducerPath: 'groupSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Groups'],
  endpoints: (builder) => ({
    // Get all groups
    tagTypes: ['Groups'],
    getGroupList: builder.query({
      query: () => 'chat/group/me',
      providesTags: ['Groups'],
    }),

    // Get a single group messages by ID
    getGroup: builder.query({
      query: (groupId) => `chats/messages/${groupId}`,
      providesTags: ['Groups'],
    }),

    // Delete a group by ID
    // ADMINS ONLY
    deleteGroup: builder.mutation({
      query: (groupId) => ({
        url: `chat/group/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),

    // Leave a group by ID
    leaveGroup: builder.mutation({
      query: (groupId) => ({
        url: `chat/group/leave/${groupId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Groups'],
    }),

    // Create a new group
    createGroup: builder.mutation({
      query: ({ title, description, leanersIds }) => ({
        url: 'chat/group',
        method: 'POST',
        body: {
          title,
          description,
          leanersIds: leanersIds,
        },
      }),
      invalidatesTags: ['Groups'],
    }),

    // Edit an existing group
    // ADMINS ONLY
    editGroup: builder.mutation({
      query: ({ groupId, title, description, leanersIds }) => ({
        url: `chat/group/${groupId}`,
        method: 'PUT',
        body: {
          title,
          description,
          leanersIds: leanersIds,
        },
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetGroupListQuery,
  useGetGroupQuery,
  useDeleteGroupMutation,
  useLeaveGroupMutation,
  useCreateGroupMutation,
  useEditGroupMutation,
} = groupsSlice;
