import { configureStore, isRejected } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import modalReducer from './slice/modalSlice';
import planSlice from './slice/planSlice';
import navigationSlice from './slice/navigationSlice';
import signupSlice from './slice/signupSlice';
import tokenSlice from './slice/tokenSlice';
import sidebar from './slice/sidebar';
import userDataSlice from './slice/userDataSlice';
import likeSlice from './slice/likeSlice';
import bookmarkSlice from './slice/bookmarkSlice';
import studyGuideSlice from './slice/studyGuide';
import questionHandleSlice from './slice/questionHandleSlice';
import stripeSlice from './slice/stripeSlice';
import amountSlice from './slice/amountSlice';
import fullScreenSlice from './slice/fullScreenSlice';
import lifePodcast from './slice/lifePodcast';
import gameSelection from './slice/gameSelection';
import questionsProgress from './slice/questionsProgress';
import videoPlayer from './slice/videoPlayerSlice';
import ttsSlice from './slice/ttsSlice';
import mobileLoginSlice from './slice/mobileLoginSlice';
import searchTextSlice from './slice/searchSlice';
import locationSlice from './slice/locationSlice';
import friendRequestSlice from './slice/friendRequest';
import dropdownSlice from './slice/dropdownSlice';
import threeDSlice from './slice/threeDSlice';
import podcastModalSlice from './slice/podcastModalSlice';
import paymentConfigSlice from './slice/paymentConfig';
import paymentStatus from './slice/paymentStatus';

import { authSlice } from './service/auth';
import { educationLevelSlice } from './service/educationLevel';
import { syllabusSlice } from './service/syllabus';
import { quickSetupSlice } from './service/quickSetup';
import { walletSlice } from './service/wallet';
import { paymentSlice } from './service/paymentChannels';
import { plansSlice } from './service/plan';
import { userSubscriptionSlice } from './service/userSubscription';
import { userPlanSlice } from './service/userPlan';
import { scratchCodeSlice } from './service/scratchCard';
import { studentSlice } from './service/student';
import { userSlice } from './service/user';
import { subjectSlice } from './service/subject';
import { feedbackSlice } from './service/feedback';
import { resourceSlice } from './service/resource';
import { noteSlice } from './service/note';
import { commentSlice } from './service/comment';
import { bookmarksSlice } from './service/bookmark';
import { studyGuidesSlice } from './service/studyGuide';
import { mockExamSlice } from './service/mockExam';
import { taskSlice } from './service/task';
import { stripeIntentSlice } from './service/stripeIntent';
import { progressTrackerSlice } from './service/progressTracker';
import { communitySlice } from './service/community';
import { viewSlice } from './service/view';
import { examSuccessSlice } from './service/examSuccess';
import { notificationSlice } from './service/notification';
import { lifeSkillSlice } from './service/lifeSkill';
import { digiSlice } from './service/digi';
import { searchSlice } from './service/search';
import { faqsSlice } from './service/faqs';
import { pushNotifySlice } from './service/pushNotify';
import { findFriendSlice } from './service/findFriend';
import { conversationsSlice } from './service/conversations';
import { referralSlice } from './service/referral';
import { leaderBoardSlice } from './service/leaderBoardSlice';
import { externalThreeDSlice } from './service/externalThreeD';
import { uploadSlice } from './service/upload';
import { sbsgSlice } from './service/sbsg';
import chatReducer from './slice/chatSlice';
import { groupsSlice } from './service/groupChats';

import { errorToast } from '../utility/toast';
import { competitionSlice } from './service/competition';
import { tutorsSlice } from './service/tutors';
import { teachatSlice } from './service/teachat';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejected(action)) {
    if (action?.payload?.status === 401) {
      localStorage.clear();
      errorToast("You don't have access, Please login");
      setTimeout(() => (window.location.href = '/login'), 200);
    }

    if (action.payload?.status === 500) {
      errorToast('Something went wrong');
      console.log('error 500');
    }

    if (action?.payload?.status === 'FETCH_ERROR') {
      errorToast('Network Error');
    }

    if (action?.payload?.status === 429) {
      errorToast('Too many retry. Please with 1 minute');
    }

    if (action?.payload?.status === 404) {
      if (action?.payload?.data?.message === 'User account not found') {
        localStorage.clear();
        errorToast(
          'User account not found, Please login with a active account'
        );
        setTimeout(() => (window.location.href = '/login'), 200);
      }
    }

    if (action?.payload?.status === 403) {
      localStorage.clear();
      errorToast(action.payload.data.message);
      setTimeout(() => (window.location.href = '/login'), 300);
    }

    if (action?.payload?.status === 400) {
      if (action.payload?.data?.message === 'Your account status is pending') {
        errorToast('You have to complete your account setup');
        window.location.href = '/sign-up/quick-setup';
      }

      // if (action.payload?.data.message.incudes('expired')) {
      //   errorToast(action?.payload?.data?.message);
      //   // dispatch()
      // }
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    plan: planSlice,
    navigation: navigationSlice,
    signup: signupSlice,
    token: tokenSlice,
    sidebar: sidebar,
    userData: userDataSlice,
    like: likeSlice,
    bookmark: bookmarkSlice,
    studyGuide: studyGuideSlice,
    questionSlice: questionHandleSlice,
    stripe: stripeSlice,
    amount: amountSlice,
    fullScreen: fullScreenSlice,
    lifePodcast: lifePodcast,
    gameSelection: gameSelection,
    questionsProgress: questionsProgress,
    videoState: videoPlayer,
    ttsSlice: ttsSlice,
    mobileScreenSlice: mobileLoginSlice,
    searchText: searchTextSlice,
    locationSlice: locationSlice,
    friendRequestSlice: friendRequestSlice,
    dropdownSlice: dropdownSlice,
    threeDSlice: threeDSlice,
    podcastModal: podcastModalSlice,
    paymentConfig: paymentConfigSlice,
    paymentStatus: paymentStatus,
    chat: chatReducer,

    [authSlice.reducerPath]: authSlice.reducer,
    [educationLevelSlice.reducerPath]: educationLevelSlice.reducer,
    [syllabusSlice.reducerPath]: syllabusSlice.reducer,
    [quickSetupSlice.reducerPath]: quickSetupSlice.reducer,
    [walletSlice.reducerPath]: walletSlice.reducer,
    [paymentSlice.reducerPath]: paymentSlice.reducer,
    [plansSlice.reducerPath]: plansSlice.reducer,
    [userSubscriptionSlice.reducerPath]: userSubscriptionSlice.reducer,
    [userPlanSlice.reducerPath]: userPlanSlice.reducer,
    [scratchCodeSlice.reducerPath]: scratchCodeSlice.reducer,
    [studentSlice.reducerPath]: studentSlice.reducer,
    [userSlice.reducerPath]: userSlice.reducer,
    [subjectSlice.reducerPath]: subjectSlice.reducer,
    [feedbackSlice.reducerPath]: feedbackSlice.reducer,
    [resourceSlice.reducerPath]: resourceSlice.reducer,
    [noteSlice.reducerPath]: noteSlice.reducer,
    [commentSlice.reducerPath]: commentSlice.reducer,
    [bookmarksSlice.reducerPath]: bookmarksSlice.reducer,
    [studyGuidesSlice.reducerPath]: studyGuidesSlice.reducer,
    [mockExamSlice.reducerPath]: mockExamSlice.reducer,
    [taskSlice.reducerPath]: taskSlice.reducer,
    [stripeIntentSlice.reducerPath]: stripeIntentSlice.reducer,
    [progressTrackerSlice.reducerPath]: progressTrackerSlice.reducer,
    [communitySlice.reducerPath]: communitySlice.reducer,
    [viewSlice.reducerPath]: viewSlice.reducer,
    [examSuccessSlice.reducerPath]: examSuccessSlice.reducer,
    [notificationSlice.reducerPath]: notificationSlice.reducer,
    [lifeSkillSlice.reducerPath]: lifeSkillSlice.reducer,
    [digiSlice.reducerPath]: digiSlice.reducer,
    [searchSlice.reducerPath]: searchSlice.reducer,
    [faqsSlice.reducerPath]: faqsSlice.reducer,
    [pushNotifySlice.reducerPath]: pushNotifySlice.reducer,
    [findFriendSlice.reducerPath]: findFriendSlice.reducer,
    [conversationsSlice.reducerPath]: conversationsSlice.reducer,
    [groupsSlice.reducerPath]: groupsSlice.reducer,
    [referralSlice.reducerPath]: referralSlice.reducer,
    [leaderBoardSlice.reducerPath]: leaderBoardSlice.reducer,
    [externalThreeDSlice.reducerPath]: externalThreeDSlice.reducer,
    [uploadSlice.reducerPath]: uploadSlice.reducer,
    [sbsgSlice.reducerPath]: sbsgSlice.reducer,
    [competitionSlice.reducerPath]: competitionSlice.reducer,
    [tutorsSlice.reducerPath]: tutorsSlice.reducer,
    [teachatSlice.reducerPath]: teachatSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authSlice.middleware,
      educationLevelSlice.middleware,
      syllabusSlice.middleware,
      quickSetupSlice.middleware,
      walletSlice.middleware,
      paymentSlice.middleware,
      plansSlice.middleware,
      userSubscriptionSlice.middleware,
      userPlanSlice.middleware,
      scratchCodeSlice.middleware,
      studentSlice.middleware,
      userSlice.middleware,
      subjectSlice.middleware,
      feedbackSlice.middleware,
      resourceSlice.middleware,
      noteSlice.middleware,
      commentSlice.middleware,
      bookmarksSlice.middleware,
      studyGuidesSlice.middleware,
      mockExamSlice.middleware,
      taskSlice.middleware,
      stripeIntentSlice.middleware,
      progressTrackerSlice.middleware,
      communitySlice.middleware,
      viewSlice.middleware,
      examSuccessSlice.middleware,
      notificationSlice.middleware,
      lifeSkillSlice.middleware,
      digiSlice.middleware,
      searchSlice.middleware,
      faqsSlice.middleware,
      pushNotifySlice.middleware,
      findFriendSlice.middleware,
      conversationsSlice.middleware,
      groupsSlice.middleware,
      referralSlice.middleware,
      leaderBoardSlice.middleware,
      externalThreeDSlice.middleware,
      uploadSlice.middleware,
      sbsgSlice.middleware,
      competitionSlice.middleware,
      tutorsSlice.middleware,
      teachatSlice.middleware,
      rtkQueryErrorLogger
    ),
});

setupListeners(store.dispatch);
