import { createSlice } from '@reduxjs/toolkit';
import io from 'socket.io-client';

let socket = null;

const initialState = {
  messages: [],
  isConnected: false,
  error: null,
  typingUsers: {},
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConnectionStatus: (state, action) => {
      // console.log('Connection status changed:', action.payload);
      state.isConnected = action.payload;
    },
    messageReceived: (state, action) => {
      const isDuplicate = state.messages.some(
        (msg) =>
          msg.timestamp === action.payload.timestamp &&
          msg.userId === action.payload.userId &&
          msg.message === action.payload.message
      );

      if (!isDuplicate) {
        state.messages.push(action.payload);
      }
    },
    messageAdded: (state, action) => {
      state.messages.push({
        ...action.payload,
        roomId: action.payload.roomId,
      });
    },
    setError: (state, action) => {
      console.log('Error state updated:', action.payload);
      state.error = action.payload;
    },
    setTypingUser: (state, action) => {
      const { userId, message } = action.payload;
      // Store the entire typing data object
      state.typingUsers[userId] = {
        userId,
        message,
      };
    },
    removeTypingUser: (state, action) => {
      const { userId } = action.payload;
      delete state.typingUsers[userId];
    },

    resetMessages: (state) => {
      state.messages = [];
    },
  },
});

export const {
  setConnectionStatus,
  messageReceived,
  setError,
  setTypingUser,
  removeTypingUser,
  resetMessages,
  messageAdded,
} = chatSlice.actions;

export const initializeSocket = () => (dispatch, getState) => {
  const token = getState().token.value.token;
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log(
  //   'Initializing socket with token:',
  //   token ? 'Token exists' : 'No token'
  // );

  if (socket?.connected) {
    console.log('Socket already connected, skipping initialization');
    return;
  }

  try {
    console.log(
      'Creating new socket connection to:',
      import.meta.env.VITE_BASE_SOCKET
    );
    socket = io(import.meta.env.VITE_BASE_SOCKET, {
      autoConnect: true,
      transports: ['polling', 'websocket'],
      withCredentials: false,
      auth: {
        token,
      },
    });

    // Make socket instance available globally
    window.socketInstance = socket;

    // Enhanced socket event logging
    socket.on('connect', () => {
      console.log('Socket successfully connected:', {
        id: socket.id,
        transport: socket.io.engine.transport.name,
      });
      dispatch(setConnectionStatus(true));
    });

    // console.log('Attempting to emit newUser event:', {
    //   userId: user.id,
    //   socketId: socket.id,
    //   isConnected: socket.connected,
    //   timestamp: new Date().toISOString(),
    // });

    socket.emit('newUser', user.id, (message) => {
      console.log('Server acknowledgment for newUser:', {
        received: !!message,
        message: message,
      });
    });

    // Add typing event listeners
    socket.on('user-typing', (data) => {
      // console.log('User typing data:', data);
      dispatch(setTypingUser(data));
    });

    socket.on('stop-typing', (data) => {
      // console.log('User stopped typing:', data);
      dispatch(removeTypingUser(data));
    });

    socket.on('error', (error) => {
      console.error('Socket error during newUser emit:', {
        error,
        socketId: socket.id,
        timestamp: new Date().toISOString(),
      });
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', {
        message: error.message,
        description: error.description,
        type: error.type,
      });
      dispatch(setError(error.message));
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', {
        reason,
        wasConnected: socket.connected,
      });
      dispatch(setConnectionStatus(false));
    });

    socket.on('receiveMessage', (message) => {
      console.log('Message received from socket:', {
        message,
        currentSocketId: socket.id,
        timestamp: new Date().toISOString(),
      });
      console.log('Received message via socket:', message);

      dispatch(messageReceived(message));
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log('Socket reconnected after attempts:', attemptNumber);
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
      console.log('Socket reconnection attempt:', attemptNumber);
    });

    socket.on('reconnect_error', (error) => {
      console.error('Socket reconnection error:', error);
    });

    socket.on('reconnect_failed', () => {
      console.error('Socket reconnection failed');
    });
  } catch (error) {
    console.error('Socket initialization failed:', {
      error: error.message,
      stack: error.stack,
    });
    dispatch(setError(error.message));
  }
};

export const sendMessage = (payload) => {
  // console.log('Send message called with payload:', {
  //   ...payload,
  // });

  if (!socket?.connected) {
    console.error('Cannot send message - socket not connected:', {
      socketExists: !!socket,
      connected: socket?.connected,
      id: socket?.id,
    });
    return;
  }

  const messagePayload = {
    ...payload,
  };

  socket.emit('sendMessage', messagePayload, (response) => {
    if (response && response.error) {
      console.error('Message send failed:', response.error);
    } else {
      console.log('Message send acknowledged:', response.message || response);
    }
  });
};

export default chatSlice.reducer;
