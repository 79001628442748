import { createSlice } from '@reduxjs/toolkit';
import StudySvg from '../../svg/Study';
import PodSvg from '../../svg/Pod';
import VideoBoxSvg from '../../svg/VideoBox';
import ExamSvg from '../../svg/Exams';
import ExamTwo from '../../svg/ExamTwo';
import TeaSvg from '../../svg/Tea';
import Dig from '../../svg/Dig';
import GameSvg from '../../svg/Game';
import SeekSvg from '../../svg/Seek';

export const planName = {
  study: {
    name: 'study',
    icon: <StudySvg />,
    subTitle: 'Acquire Knowledge',
    free: true,
    background:
      'customise__card-container-item-one custom__card-container-item-one',
    back: 'box__card-container-item-one',
    id: '654378209a9f17912f9f8a45',
  },
  'exam success': {
    name: 'exam success',
    icon: <ExamTwo />,
    subTitle: 'Get that A+',
    background:
      'customise__card-container-item-five custom__card-container-item-five',
    back: 'box__card-container-item-five',
    id: '65437f4e5237c06e2b49691c',
  },
  podcasts: {
    name: 'podcasts',
    icon: <PodSvg />,
    subTitle: 'Listen to Lectures',
    background:
      'customise__card-container-item-two custom__card-container-item-two',
    back: 'box__card-container-item-two',
    podcast: '65437846f9b2a2ed5f5a479e',
  },
  videos: {
    name: 'videos',
    icon: <VideoBoxSvg />,
    subTitle: 'Watch Tutorial Videos',
    background:
      'customise__card-container-item-three custom__card-container-item-three',
    back: 'box__card-container-item-three',
    id: '65437830be4d30027e387777',
  },
  exams: {
    name: 'exams',
    icon: <ExamSvg />,
    subTitle: 'Answer Past Questions',
    background:
      'customise__card-container-item-four custom__card-container-item-four',
    back: 'box__card-container-item-four',
    id: '65437879968f60943f649326',
  },
  teachat: {
    name: 'teachat',
    icon: <TeaSvg />,
    subTitle: 'Teacher Support & Homework Assistance',
    background:
      'customise__card-container-item-six custom__card-container-item-six',
    back: 'box__card-container-item-six',
    id: '654381cb94381cde4128e4f7',
  },
  'seek counsel': {
    name: 'seek counsel',
    icon: <SeekSvg />,
    subTitle: 'Academic & Career Assistance',
    background:
      'customise__card-container-item-seven custom__card-container-item-seven',
    back: 'box__card-container-item-eleven',
    id: '6560691ac5b17cc2e584627e',
  },
  digipreneur: {
    name: 'digiprenuer',
    icon: <Dig />,
    subTitle: 'Learn Top Skills',
    background:
      'customise__card-container-item-eight custom__card-container-item-eight',
    back: 'box__card-container-item-eight',
    id: '65438465374ca89e16502b42',
  },
  games: {
    name: 'games',
    icon: <GameSvg />,
    subTitle: 'Play educational Games',
    background:
      'customise__card-container-item-nine custom__card-container-item-nine',
    back: 'box__card-container-item-nine',
    id: '654382f03856e57329d027ce',
  },
  'life skills': {
    name: 'life skills',
    icon: <GameSvg />,
    subTitle: 'Learn Top Skills',
    background:
      'customise__card-container-item-ten custom__card-container-item-ten',
    back: 'box__card-container-item-ten',
    id: '6554dbbc2ef66df29c43b328',
  },
};

export const initialState = {
  value: Object.keys(planName),
  planDetails: {
    plan: '',
    price: 0,
    duration: '',
    subscribedPlanId: '',
    subscribedPlanItemsId: [],
  },
};

const checkList = ['study', 'digipreneur', 'seek counsel'];

export const planSlice = createSlice({
  name: 'planSlice',
  initialState,
  reducers: {
    togglePlan: (state, item) => {
      const check = state.value.includes(item.payload.name.toLowerCase());
      if (check) {
        if (!checkList.includes(item.payload.name.toLowerCase())) {
          if (item.payload.name.toLowerCase() === 'exam success') {
            state.value = ['study', 'seek counsel', 'digipreneur'];
          } else {
            const removeSelected = state.value.filter(
              (selected) => selected !== item.payload.name.toLowerCase()
            );
            if (removeSelected.includes('exam success')) {
              const update = removeSelected.filter(
                (icon) => icon !== 'exam success'
              );
              state.value = update;
            } else {
              state.value = removeSelected;
            }
          }
        }
      } else {
        if (item.payload.name.toLowerCase() === 'exam success') {
          state.value = Object.keys(planName);
        } else {
          state.value = [item.payload.name.toLowerCase(), ...state.value];
        }
      }
    },
    selectedPlan: (state, item) => {
      const { plan, price, duration, subscribedPlanId, subscribedPlanItemsId } =
        item.payload;
      state.planDetails = {
        plan,
        price,
        duration,
        subscribedPlanId,
        subscribedPlanItemsId,
      };
    },
  },
});

export const { togglePlan, selectedPlan } = planSlice.actions;

export default planSlice.reducer;
