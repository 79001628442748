import './index.scss';
import backgroundImage from '../../assets/loader-no-background.gif';

const LoaderCenter = ({ hideBackGround }) => {
  return (
    <div
      className={`loader-center`}
      style={{
        background: hideBackGround ? 'transparent' : 'rgba(0, 0, 0, 0.05)',
      }}
    >
      <img src={backgroundImage} width="200px" alt="loading" />
    </div>
  );
};

export default LoaderCenter;
