const Icon = () => {
  return (
    <svg
      fill="none"
      height="71"
      viewBox="0 0 80 71"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.14 24.993a3.39 3.39 0 00-2.251-.856l2.251.856zm0 0a3.39 3.39 0 011.112 2.122m-1.112-2.122l1.112 2.122m0 0l.026.426v15.54s0 0 0 0a3.389 3.389 0 01-6.753.41l-.025-.425v-15.54a3.389 3.389 0 013.389-3.39l3.363 2.98z"
        fill="#4BA347"
        fillOpacity="0.65"
        stroke="#000"
      />
      <path
        d="M36.889 13.137a3.389 3.389 0 013.389 3.389v38.888a3.389 3.389 0 01-6.778 0V16.526a3.389 3.389 0 013.389-3.39z"
        fill="#4BA347"
        fillOpacity="0.55"
        stroke="#000"
      />
      <path
        d="M21 6.137c.888 0 1.769.44 2.438 1.277.672.84 1.062 1.998 1.062 3.223v50c0 1.225-.39 2.383-1.062 3.223-.67.837-1.55 1.277-2.438 1.277-.888 0-1.769-.44-2.438-1.277-.672-.84-1.062-1.998-1.062-3.223v-50c0-1.225.39-2.384 1.062-3.223.67-.837 1.55-1.277 2.438-1.277z"
        fill="#4BA347"
        fillOpacity="0.45"
        stroke="#000"
      />
      <path
        d="M7.473 1.62A3.889 3.889 0 001 4.526v62.222l.027.455a3.889 3.889 0 007.75-.455V4.526l-.026-.455A3.889 3.889 0 007.473 1.62z"
        fill="#4BA347"
        fillOpacity="0.35"
      />
      <path
        d="M78.296 33.181c1.545.85 1.545 3.07 0 3.919L67.564 43c-1.49.82-3.314-.259-3.314-1.96V29.24c0-1.7 1.823-2.779 3.314-1.96l10.732 5.902z"
        fill="#4BA347"
        fillOpacity="0.75"
      />
      <path
        clipRule="evenodd"
        d="M67.025 42.021l10.732-5.9a1.118 1.118 0 000-1.96L67.025 28.26a1.118 1.118 0 00-1.657.98v11.802c0 .85.912 1.39 1.657.98zm11.27-4.921c1.546-.85 1.546-3.07 0-3.919L67.565 27.28c-1.49-.82-3.314.259-3.314 1.96v11.802c0 1.7 1.823 2.779 3.314 1.96L78.296 37.1z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Icon;
